import { get } from 'lodash';

export default prismicData => ({
  posts: get(prismicData, 'allPrismicBlogPost.nodes', []).map(post => ({
    ...get(post, 'data'),
    uid: post.uid,
    url: `/blog/${post.uid}`,
    last_publication_date: post.last_publication_date,
    author: get(post, 'data.author.document.data')
  }))
});
