import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import unfurl from '~prismic/unfurl/blog';

import Page from '~layout/Page';
import Container from '~components/Container';
import BlogHead from '~components/blog';

const Blog = ({ data }) => {
  const { posts } = unfurl(data);

  return (
    <Page>
      <Container maxWidth="md">
        {posts &&
          posts.map((post, index) => (
            <BlogHead key={post.title.html} isFeatured={index === 0} {...post} />
          ))}
      </Container>
    </Page>
  );
};

export default Blog;

Blog.propTypes = {
  data: PropTypes.shape().isRequired
};

export const query = graphql`
  {
    allPrismicBlogPost(sort: { fields: [last_publication_date], order: DESC }) {
      nodes {
        uid
        last_publication_date(formatString: "MMMM D, YYYY")
        data {
          title {
            text
            html
          }
          overline {
            text
            html
          }
          content {
            html
            text
          }
          author {
            document {
              ... on PrismicAuthor {
                data {
                  name
                  role
                  avatar {
                    url
                    alt
                  }
                }
              }
            }
          }
          seo_title
          seo_description
          seo_keywords
          seo_image {
            url
            alt
          }
        }
      }
    }
  }
`;
